@import "./constants.scss";
@import "./common.scss";

.services {
    // display: flex;
    // flex-wrap: wrap;
    .service {
        // flex-basis: 33%;
        &-title::after {
            display: block;
            content: "";
            height: 1px;
            background-color: $alternative-background-color;
            opacity: .2;
            margin-top: .5em;
        }

        margin-bottom: 2em;

        .details {
        }

        &-detail {
            padding: .5em;
            // @include belowLarge {
            //     padding: .2em .5em;
            // }
            &-title {
                opacity: .8;
                margin-bottom: .5em;
                @include belowLarge {
                    margin: .2em 0;
                }
            }
            &-description {
                opacity: .7;
            }
        }
    }
}