@import "./constants.scss";
@import "./common.scss";

$menu-underline-height: 2px;

html,
body,
div {
	box-sizing: border-box;
	padding: 0;
	margin: 0;
}

header {
	z-index: 1000;
	background-color: $background-color;
	box-sizing: border-box;
	position: fixed;
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);

	.hamburger-lines {
		display: block;
		height: 26px;
		width: 32px;
		position: absolute;
		top: 26px;
		left: 26px;
		z-index: 2;
		display: flex;
		flex-direction: column;
		justify-content: space-between;

		@include atLarge {
			display: none;
		}
	}

	.hamburger-lines .line {
		display: block;
		height: 4px;
		width: 100%;
		border-radius: 10px;
		background: #0e2431;
	}

	.hamburger-lines .line1 {
		transform-origin: 0% 0%;
		transition: transform 0.4s ease-in-out;
	}

	.hamburger-lines .line2 {
		transition: transform 0.2s ease-in-out;
	}

	.hamburger-lines .line3 {
		transform-origin: 0% 100%;
		transition: transform 0.4s ease-in-out;
	}

	#menuToggle:checked~.hamburger-lines {
		.line1 {
			transform: rotate(45deg);
		}

		.line2 {
			transform: scaleY(0);
		}

		.line3 {
			transform: rotate(-45deg);
		}
	}

	#menuToggle {
		position: absolute;
		display: block;
		height: 32px;
		width: 32px;
		top: 24px;
		left: 24px;
		z-index: 5;
		opacity: 0;
		cursor: pointer;

		@include atLarge {
			display: none;
		}

		@include belowLarge {
			&:checked~nav {
				height: 100vh;
				ul {
					display: block;
				}
			}
		}
	}

	.logo {
		height: $header-height;
		width: $header-height;
		padding: .2em;
		img {
			height: 100%;
			width: auto;
		}
	}

	nav {
		transition: all 500ms cubic-bezier(0.075, 0.82, 0.165, 1);
		height: $header-height;
		flex-grow: 1;
		align-content: center;
		position: relative;
		display: none;
		display: flex;

		ul {
			margin: auto;
			display: none;
		}
		
		@include atLarge {
			ul {
				display: block;
				margin: 0 auto;
				li {
					display: inline-block;
					padding: 0 .8em;
					height: 100%;

					&.dropdown {
						ul {
							position: absolute;
							display: none;
							margin: 0;
							padding: 0;

							li {
								margin: 0;
								display: block;
							}
						}
					}

					&.dropdown:hover {
						ul {
							display: block;
						}
					}
				}
			}
		}
	}

	@keyframes scale-move-in {
		0% {
			transform: scaleX(0%);
		}

		100% {
			transform: scaleX(100%);
		}
	}

	nav > ul > li > a {
		display: inline-block;
		color: $text-color;
		opacity: .5;
		height: 100%;
		line-height: calc(#{$header-height} - #{$menu-underline-height});
		transition: opacity 150ms ease-in-out;
		text-transform: uppercase;

		&:after {
			display: block;
			content: '';
			height: 2px;
			width: 100%;
			background-color: $accent-color;
			transform: scaleX(0%);
			// from hover class to non hover put animation backwards - da se svivat
		}
		

		&:hover, &.active {
			opacity: 100%;
			&:after {
				animation: scale-move-in 250ms forwards;
			}
		}
	}

	.logo,
	.languages {
		display: none;
		@include atLarge {
			display: flex;
			position: relative;
		}
	}
}
