@import "./common.scss";

.portfolio-project:after {
    content: "";
    display: block;
    padding-bottom: 56%;
  }

.portfolio-project {
    display: flex;
    justify-content: start;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    text-decoration: none !important;
    margin: 0.8rem !important;

    @include atLarge() {

        &:nth-of-type(3n - 2) {
            margin-left: 2.2% !important;
        }
        
        &:nth-of-type(3n) {
            margin-right: 2.2% !important;
        }
    }

    h6 {
        align-self: flex-end;
        text-align: left;
        transition: all 300ms ease-in-out;
        color: white;
        z-index: 100;
        font-size: 1.5em;
        padding: 0 1em;
        overflow: hidden;
        margin-block-start: 0;
        margin-block-end: 0;
        background-color: rgba($color: #000000, $alpha: .5);
        width: 100%;
        overflow: visible;
        position: relative;
        padding-bottom: .5em;
        font-weight: 100;

        &::before {
            height: 50px;
            width: 100%;
            content: "";
            display: block;
            position: absolute;
            top: -50px;
            left: 0;
            background: linear-gradient(0deg, rgba(0,0,0,.5) 0%, rgba(0,0,0,0) 100%);
        }

        @include atAndBelowTablet {
            font-size: 1em;
        }

        @include belowLarge() {
            font-size: 2em;
        }
    }

    .background {
        cursor: pointer;
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        background-position: center;
        background-size: cover;
        filter: brightness(100%); 
        transition: all 300ms ease-in-out;
        transform: scale(1);
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;

        @include atAndBelowTablet {
            filter: brightness(80%); 
        }

        img {
            object-fit: cover;
            object-position: center;
            min-height: 100%;
            min-width: 100%;
        }
    }

    &:hover {
        cursor: pointer;
        .background {
            filter: brightness(50%); 
            transform: scale(1.1);
        }
        h6 {
            opacity: 1;
        }
    }
}

.single-project {
    position: relative;
    .video-carousel-button {
        position: absolute;
        width: 5em;
        height: 5em;
        border: 1px solid red;
        background-size: contain;
        top: 50%;

        &.next {
            right:-3em;
        }

        &.left {
            left: -3em;
        }
    }
    
    .control-arrow.control-prev {
        &::before{
            border-right-color: black !important;
        }
    }
    .control-arrow.control-next {
        &::before{
            border-left-color: black !important;
        }
    }
}
