.clients {
    .flex {
        display: flex;
        flex-direction: row;
        .clients-subtitle {
            align-self: baseline;
            align-self: center;
        }
    }

    .clients-row {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        .client {
            width: 25%;
            padding: 1.5em;
            display: inline-block; 
            align-self: center;
            img {
                display:  inline-block;
                width: 100%;
            }
        }
    }
}