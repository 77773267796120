@import "./styles/constants.scss";
@import "./styles/simple-grid.scss";
@import "./styles/common.scss";

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#root {
  margin: 0 auto;
  width: 100%;
}
ul {
  li {
      list-style-type: none;
      a {
          text-decoration: none;
      }
  }
}

.capabilities {
  h4 {
    display: inline-block;
  }
  .capability-description {
    font-style: italic;
    margin-left: .5em;
    display: inline-block;
  }
  .capability-list {
    ul li {
      list-style-type: disc;

    }
  }
}

main {
	padding-top: $header-height;
  padding-bottom: $header-height;
}

.autovideo {
  overflow: hidden;
  video {
    max-width: 100vw;
    height: 80vh;
    object-fit: cover;
    position: absolute;
    top: $header-height;
    left: 0;
  }
}

.slogan-home {
	height: 80vh;
	background-position: center;
	background-size: cover;
	display: flex;
	align-items: center;
	justify-content: center;
	h1 {
		color: white;
		margin: 0 auto;
	}
}

#scrollToTop, #contactUsPhone, #contactUsMail {
  display: none;
  position: fixed;
  width: 50px;
  height: 50px;
  border-radius: 25px;
  border: none;
  background-color: black;
  opacity: 0.4;
  bottom: 1em;
  right: 1em;
  z-index: 10000;
  cursor: pointer;
  &::before {
    margin: 0 5px 2px 5px;
    display: inline-block;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid #fff;
    content: '';
  }

  &:hover {
    opacity: 0.6;
  }

  @include atAndBelowTablet {
    display: block; 
  }
}

#contactUsPhone, #contactUsMail {
  display: block;
  bottom: .8em;
  &::before {
    display: none;
  }
}

#contactUs {
  text-align: center;
  svg {
    margin-top: .75em;
    width: 50%;
    height: 50%;
    fill: white;
  }
}

#contactUsPhone {
  right: 4.4em;
}

#contactUsMail {
  right: 8em;
}

#detach-button-host {
  display: none !important;
}

@import "./styles/portfolio.scss";
@import "./styles/header.scss";
@import "./styles/footer.scss";
@import "./styles/project.scss";
@import "./styles/common.scss";
@import "./styles/clients.scss";
@import "./styles/team.scss";
@import "./styles/services.scss";
@import "./styles/contact.scss";
@import "./styles/rentals.scss";

@import "./styles/adorners.scss";
