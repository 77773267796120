@import "./common.scss";

.team {
    display: flex;
    flex-wrap: wrap;
    .member {
        flex-basis: 33%;
        @include belowLarge {
            flex-basis: 100%;
        }

        position: relative;
        overflow: hidden;
        padding: 1em;
        
        img {
            width: 100%;
        }
        &-name {
            width: 100%;
            text-align: center;
            margin-top: 1em;
            font-weight: 500;
        }

        &-bio {
            // position: absolute;
            padding: 1em;
            padding-top: 0;
        }

        .member-background {
            &:after {
                content: "";
                display: block;
                padding-bottom: 100%;
            }
            z-index: -5;
            width: 100%;
            position: relative;
            div {
                position: absolute;
                width: 100%;
                height: 100%;
                background-position: center;
                background-size: cover;
            }

            .animated {
                opacity: 0;
                @include belowLarge {
                    opacity: 1;
                }
                transition: opacity 100ms ease-in;
            }

        }
        
        &:hover .animated {
            opacity: 1;
        }
    }
}
