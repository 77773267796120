@import "./constants.scss";

footer {
    background-color: $alternative-background-color;
    color: $alternative-subtext-color;
    padding: 2em 0;
    display: flex;
    justify-content: center;

    .footer-title {
        color: $alternative-text-color;
        text-transform: uppercase;
    }

    .footer-subtitle, .footer-contacts, .footer-socials {
        color: $alternative-subtext-color;
    }

    .footer-socials {
        padding-top: 25px;
        span {
            float: left;
            width: 20px;
            height: 20px;
            margin-right: 7px;
            svg {
                fill: $alternative-subtext-color;
            }
        }
    }

    .footer-logo {
        max-width: 5em;
        max-height: 5em;
        filter: invert(88%) brightness(60%) contrast(97%);
    }

    .footer-copyright {
        font-size: .8em;
        text-align: center;
    }
}
