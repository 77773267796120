@import "./constants.scss";

@mixin atLarge(){
    @media screen and (min-width: $TABLET){
        @content;
    }
}

@mixin belowLarge(){
    @media screen and (max-width: $TABLET) {
        @content;
    }
}

@mixin atAndBelowTablet() {
    @media screen and (max-width: $MEDIUM) {
        @content;
    }
}

.margin-top {
    &-s {
        margin-top: 2em;
    }
    &-m {
        margin-top: 3em;
    }
    &-l {
        margin-top: 4em;
    }
    &-xl {
        margin-top: 5em;
    }
}

.margin-bottom {
    &-s {
        margin-bottom: 2em;
    }
    &-m {
        margin-bottom: 3em;
    }
    &-l {
        margin-bottom: 4em;
    }
    &-xl {
        margin-bottom: 5em;
    }
}

.text-middle {
    text-align: center;
}

.text-vertical-middle {
    vertical-align: middle;
}

.large-title {
    font-size: 4em;
    text-transform: uppercase;
    @include belowLarge {
        font-size: 3em;
    }
}

.underline {
    &::after {
        background-color: $accent-color;
        display: block;
        height: 4px;
        width: 100px;
        content: "";
        margin: 0 auto;
    }
     
    &.start::after {
        margin: 0;
    }

    &.end::after {
        margin-right: 0;
    }
}

.large-title {
    &.underline::after {
        margin-top: 15px;
    }
}

.subtitle {
    font-size: 1.5em;
}

.height-80 {
    &::after {
        padding-bottom: 80%;
        display: block;
        content: "";
    }
}

.contain-img {
    img {
        width: 100%;
        height: auto;
    }
}
