
.project-details {
    padding: 2em 0;
    .project-title {
        margin: 0;
        margin-bottom: .6em;
        font-weight: 800;
        font-size: 2em;
    }

    .project-description {
        font-weight: 400;
        font-size: 1.2em;
    }

    .details-title {
        font-weight: 600;
        text-transform: uppercase;
    }
}

.project-gallery {
    div {
        img {
            width: 100%;
            height: auto;
        }
    }
    
    .project-gallery-subtitle {
        margin-top: 1.5em;
        margin-bottom: 1.5em;
        font-size: 1.3em;
    }
}

.single-project {
    padding-top: 3em;
}