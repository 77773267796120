.rental-category {
    .title {
        text-align: center;
        font-weight: 400;
        &::after {
            display: block;
            content: "";
            height: 1px;
            background-color: $alternative-background-color;
            opacity: .2;
            margin-top: .5em;
        }
    }
    
    .subtitle {
        font-weight: 400;
        margin-bottom: 0;
        text-align: center;
        
        &::after {
            display: block;
            content: "";
            height: 1px;
            background-color: $alternative-background-color;
            opacity: .2;
            margin: 0 auto;
            margin-top: .5em;
            width: 60%;
        }
    }

    .rental-product{
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 20px;

        img {
            max-height: 100%;
            margin: 0;
            max-width: 20%;
        }

        div {
            vertical-align: middle;
            height: 100%;
            display: inline-block;
        }

        .name {
            font-size: 1.3em;
            font-weight: 400;
            flex-grow: 4;
        }

        .quantity {
            font-style: italic;
        }
    }
}
