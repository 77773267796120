$accent-color: #FFBF00;
$header-height: 5em;
$background-color: white;
$alternative-background-color: #151515;
$text-color: rgba(49, 49, 49, 1);
$alternative-text-color: white;
$alternative-subtext-color: rgba(255, 255, 255, 0.5);
$LARGE: 1400px;
$MEDIUM: 1024px;
$SIDEBAR: 992px;
$TABLET: 720px;
$SMALL: 600px;
$TINY: 400px;