*[data-is-editable='true']:hover {
    border: 3px solid red;
    position: relative;
    &:before{
        content: "edit";
        position: absolute;
        display: block;
        top: 0;
        right: 0;
        font-size: 25px;
        background-color: red;
        cursor: pointer;
        z-index: 10000;
    }
}